.article__content{
    margin-top: $spacer * 2;
    text-align: justify;
}
.project__img{
    width: 100%;
}
.img-post__container{
    .project__img{
        padding: 0 2px;
    }   
}
.project__title{
    letter-spacing: 3px;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: $spacer / 2;

    @include media-breakpoint-mobile{
        margin-top: $spacer;
    }
}
.project__footer{
    display: flex;
    justify-content: space-between;
    .project-footer__back-btn{
        text-decoration: none;
        font-size: 14px;
    }
    .footer-next-prev{
        text-decoration: none;
        font-size: 14px;
        margin: 0 $spacer;
    }
}


// .main__container{
//     background-image: url("../img/chaillot3372.jpg");
//     background-size: cover;
//     background-repeat: no-repeat;
//     height: 100vh;
//     position: relative;
//     &::after{
//         z-index: 1;
//         content: " ";
//         position: absolute;
//         display: block;
//         height: 100%;
//         width: 100%;
//         top: 0;
//         left: 0;
//         background-color: rgba(0, 0, 0, .7);
//     }
// }
// .index_logo{
//     font-family: "Dosis",sans-serif;
//     text-transform: uppercase;
//     color: white;
//     font-weight: 800;
//     font-size: (24 / 16) * $spacer;
//     letter-spacing: (4 / 16) * $spacer;
// }
// .main__content{
//     z-index: 2;
//     position: relative;
//     padding: $spacer * 2 $spacer * 4;
// }
// .index__btn-project{
//     color: white;
//     padding: $spacer / 2 $spacer * 2;
//     border: white 1px solid;

//     &:hover{
//         background-color: white;
//         color: black;
//     }
// }