.about__photo{
    margin-bottom: $spacer * 2;
}
.about__content-text{
    text-align: justify;
}
.about__image-container{
    background-image: url(/img/sebB&w.jpg);
    background-position: center;
    background-size: cover;
};
.about-content__last-text{
    margin-bottom: 0;
}

@include media-breakpoint-mobile{
    .about__image-container{
        height: 600px;
    }
}