.contact__photo{
    display: block;
    margin: 0 auto $spacer auto;
    @include media-breakpoint-mobile{
        width: 100%;
    }
}
.contact__address{
    color: #727272;
    text-align: center;
}
.contact__email{
    color: #727272;
    text-align: center;
}
.contact{
    .header__logo{
        font-family: "Dosis",sans-serif;
        text-transform: capitalize;
        color: black;
        font-weight: 800;
        letter-spacing: (4 / 16) * $spacer;
        text-align: center;
        .header-logo__sideText{
            text-transform: initial;
            font-family: "Raleway",sans-serif;
            font-weight: 300;
            font-size: 12px;
        }
        
    }
}
