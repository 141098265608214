body{
  font-size: 14px;
  letter-spacing: .5px; 
}
h1{
  font-family: "Raleway",sans-serif;

}
.page__title{
  font-size: 14px;
  font-weight: 800;
  margin-bottom: $spacer / 2;
}
.contact{
  .page__title{
    text-align: center;
  }
}
.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  
    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
  

  a{
    text-decoration: none;
    color: #727272;
    &:hover{
        transition: all .2s;
        text-decoration: none;
        color: #000000;
    }
}

.footer__modal{
  display: block;
  position: fixed;
  z-index: 10;
  right: -1000px;
  top: 0;
  background: white;
  width: 80%;
  height: 100%;
  transition: right .7s cubic-bezier(.72,.02,.65,.95);
}
.footer__modal-header{
  padding: $spacer;

  &::after{
    display: block;
    background: rgb(198, 198, 198);
    height: 1px;
    margin-top: $spacer; 
    content: "";
  }
}
.backdrop{
  opacity: 0;
  display: none;
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.521);
  height: 100%;
  width: 100%;
  transition: opacity .3s cubic-bezier(.72,.02,.65,.95);
}

.visible--desktop{
  @include media-breakpoint-mobile(){
    display: none !important;
  }
}
.visible--mobile{
  @include media-breakpoint-desktop(){
    display: none !important;
  }
}