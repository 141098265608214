.header{
    margin-top: $spacer * 2;
    margin-bottom: $spacer * 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header__logo{
        font-family: "Dosis",sans-serif;
        text-transform: capitalize;
        color: black;
        font-weight: 800;
        letter-spacing: (4 / 16) * $spacer;      
    }
    .header-logo__sideText{
        font-family: "Raleway",sans-serif;
        text-transform: initial;
        font-weight: 300;
        font-size: 12px;
        letter-spacing: (4 / 16) * $spacer; 
    }
}
.header__nav{
    display: flex;
    list-style: none;
    margin: 0;
    @include media-breakpoint-mobile{
        padding: 0 $spacer * 2;
        flex-direction: column;
    }
    .header__nav-item{
        margin: 0 $spacer;
        @include media-breakpoint-mobile{
            margin: (5 / 16) * $spacer 0;
        }
    }
}
.header-offcanvastoggler{
    -webkit-appearance: none;
    width: 100%;
    border: 1px solid black;
    z-index: 3;
}

@include media-breakpoint-mobile{
    .header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}