@font-face {
    font-family: 'Dosis';
    src: url('../fonts/Dosis-Bold.eot');
    src: url('../fonts/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Dosis-Bold.woff2') format('woff2'),
        url('../fonts/Dosis-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis';
    src: url('../fonts/Dosis-Regular.eot');
    src: url('../fonts/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Dosis-Regular.woff2') format('woff2'),
        url('../fonts/Dosis-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Light.eot');
    src: url('../fonts/Raleway-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Light.woff2') format('woff2'),
        url('../fonts/Raleway-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Regular.eot');
    src: url('../fonts/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Regular.woff2') format('woff2'),
        url('../fonts/Raleway-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Italic.eot');
    src: url('../fonts/Raleway-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Italic.woff2') format('woff2'),
        url('../fonts/Raleway-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-Bold.eot');
    src: url('../fonts/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-Bold.woff2') format('woff2'),
        url('../fonts/Raleway-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-BoldItalic.eot');
    src: url('../fonts/Raleway-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-BoldItalic.woff2') format('woff2'),
        url('../fonts/Raleway-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('../fonts/Raleway-LightItalic.eot');
    src: url('../fonts/Raleway-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Raleway-LightItalic.woff2') format('woff2'),
        url('../fonts/Raleway-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

p,a{
    font-family: "Raleway",sans-serif;
    font-weight: 300;
}
