.project{
    height: 400px;
    margin-top:(30 / 16) * $spacer;
    @include media-breakpoint-mobile{
        height: 250px;
    }
    .project__img{
        height: 100%;
        background-size: cover;
        position: relative;
        &:hover .project__hover{
            padding: 16px;
            opacity: 1; 
        }
    }
    .project__hover{
        opacity: 0;
        display: flex;
        transition: opacity .14s ease-out;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: rgba(248, 248, 255, 0.927);
        transition: all .4s;
        height: 100%;
        &-content{
            z-index: 2;
        }
    }
}